import React, { useMemo, useState, useCallback, useRef } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Image } from 'react-bootstrap';

import { UqPage, UqLogo, UqLayoutV2, UqHeroV2, UqContainer, UqPortraitDetails, UqLink, UqTileRow, UqTypography, UqAppBarV2, UqScrollContent, UqContainerV2 } from '@uq-components';
import { UqEventAddress, UqEventTimeline, UqFeaturedEvent, UqEventRegistrationDialog } from '../components/v2/events';
import { WAITLIST_EVENT } from '../content/v2/events';
import { eventShape } from '../models';

import * as styles from './_event.module.scss';

UqEventPage.propTypes = {
  pageContext: PropTypes.shape({
    event: eventShape,
  }),
};

export default function UqEventPage(props) {
  const { event } = props.pageContext;

  const [eventToRegister, setEventToRegister] = useState(null);
  const signUpHeaderRef = useRef(null);

  const closeModal = useCallback(() => setEventToRegister(null), []);

  const agenda = useMemo(() => {
    return event.agenda.map((item) => ({
      ...item,
      notes: item.notes?.notes || null,
    }));
  }, [event]);

  return (
    <UqPage>
      <UqLayoutV2>
        <UqAppBarV2
          static
          className={styles.appbar}
        >
          <UqContainerV2 className={styles.content}>
            <UqLogo />

            <UqScrollContent thresholdPx={500}>
              {renderSignUpHeader(event)}
            </UqScrollContent>
          </UqContainerV2>
        </UqAppBarV2>

        <UqContainer
          fullWidth
          background="dark"
          className={styles.hero}
        >
          <UqHeroV2>

            <UqContainer className={styles.heroTitle}>
              <section className={styles.titleCol}>
                <div className={styles.eventType}>
                  {event.type}
                </div>
                <UqTypography as="h1">
                  {event.title}
                </UqTypography>
                <h4 className={styles.dateTime}>
                  {event.enableRegistration ? event.dateTime : 'Guest list is closed'}
                </h4>
                <UqEventAddress
                  address={event.address}
                  url={event.googleMapsUrl}
                  venue={event.venue}
                />
                <p className={classNames('body-2', styles.description)}>
                  {event.enableRegistration
                    ? event.description.description
                    : `Sorry we missed you! Our recent Quality Soirée in ${event.city}
                  was absolutely magical with moments of learning, networking, and joy. We'd love for you to be part of our next event.
                  Want in? Just hop onto our waiting list, and we'll keep you in the loop for upcoming events.`}
                </p>
                <Button
                  className="cta wide"
                  onClick={() => setEventToRegister(event.enableRegistration ? event : WAITLIST_EVENT)}
                >
                  {getCTAText(event.enableRegistration)}
                </Button>
              </section>
            </UqContainer>
            <Image
              className={styles.heroImage}
              src={event.image.url}
            />
          </UqHeroV2>
        </UqContainer>

        <UqContainer>
          <section className={styles.eventInfo}>
            <UqTypography
              as="h2"
              className={styles.eventInfoTitle}
            >
              What to !-look-! forward to
            </UqTypography>
            <UqTileRow tiles={event.whatToExpect} />
          </section>
        </UqContainer>
        {!!event.speakers?.length && (
          <UqContainer>
            <h2 className={styles.featuredGuestsTitle}>
              A few featured guests
            </h2>
            <section className={styles.featuredGuests}>
              {event.speakers.map((speaker, index) => (
                <div key={speaker.id}>
                  {renderSpeaker(speaker, index)}
                  {index !== event.speakers.length - 1 && <hr className={styles.divider} />}
                </div>
              ))}
            </section>
          </UqContainer>
        )}
        {!!event.meetAndGreet?.length && (
          <UqContainer>
            <h2 className={styles.meetAndGreetTitle}>
              Meet and greet unitQ leaders
            </h2>
            <section className={styles.meetAndGreet}>
              {event.meetAndGreet.map((vip) => (
                <div
                  className={styles.meetAndGreetVip}
                  key={vip.id}
                >
                  <UqPortraitDetails
                    classes={{
                      image: styles.vipThumbnail,
                      root: styles.vip,
                    }}
                    name={vip.name}
                    description={vip.description.description}
                    portraitSize="sm"
                    role={vip.role}
                    src={vip.thumbnail.url || vip.image.url}
                    textAlign="right"
                  />
                </div>
              ))}
            </section>
          </UqContainer>
        )}
        <section className={styles.agenda}>
          <UqContainer
            background="dark"
            fullWidth
          >
            <UqContainer>
              <div className={styles.agendaCard}>
                <h2 className={styles.agendaTitle}>
                  Evening Schedule
                </h2>
                <div className={styles.agendaTimeline}>
                  <UqEventTimeline events={agenda} />
                </div>
              </div>
            </UqContainer>
          </UqContainer>
        </section>
        {!!event.featuredEvents?.length && (
          <UqContainer>
            <section className={styles.nextEvents}>
              <UqTypography
                as="h2"
                className={styles.nextEventsTitle}
              >
                Where we are !-headed-!
              </UqTypography>
              <div className={styles.nextEventsRow}>
                {event.featuredEvents?.map((featuredEvent) => {
                  return (
                    <UqFeaturedEvent
                      key={featuredEvent.id}
                      dateTime={featuredEvent.dateTime}
                      src={featuredEvent.thumbnail?.url || featuredEvent.image?.url}
                      title={featuredEvent.city}
                      venue={featuredEvent.venue}
                      link={(
                        <UqLink
                          to={`/events/${featuredEvent.slug}`}
                          onClick={(event) => {
                            event.preventDefault();

                            if (featuredEvent.url) {
                              window.open(featuredEvent.url);
                              return;
                            }

                            // If a dedicated page is not created, use the dialog
                            if (!featuredEvent.createPage) {
                              setEventToRegister(featuredEvent);
                              return;
                            }

                            navigate(`/${featuredEvent.slug}`);
                          }}>
                          <span>
                            {getCTAText(featuredEvent.enableRegistration || !featuredEvent.createPage)}
                          </span>
                        </UqLink>
                      )}
                    />
                  );
                })}
                <UqFeaturedEvent
                  key="wait-list"
                  dateTime="Can’t make it? Get on our wait list!"
                  src="/images/v2/events/next-time-placeholder.jpeg"
                  title="Next Time"
                  link={(
                    <UqLink onClick={() => setEventToRegister(WAITLIST_EVENT)}>
                      Keep Me informed
                    </UqLink>
                  )}
                />
              </div>
            </section>
          </UqContainer>
        )}
        <UqEventRegistrationDialog
          event={eventToRegister}
          onClose={closeModal}
          open={!!eventToRegister}
        />
      </UqLayoutV2>
    </UqPage >
  );

  function renderSignUpHeader(event) {
    return (
      <div
        ref={signUpHeaderRef}
        className={styles.signUpHeader}
      >
        {event.enableRegistration && (
          <div className={classNames('body-1', styles.signUpHeaderDate)}>
            {event.dateTime}
          </div>
        )}
        <Button onClick={() => setEventToRegister(event.enableRegistration ? event : WAITLIST_EVENT)}>
          {getCTAText(event.enableRegistration)}
        </Button>
      </div>
    );
  }

  function renderSpeaker(speaker, index) {
    const isOdd = index % 2 !== 0;

    if (isOdd) {
      return (
        <>
          <div className="d-lg-none">
            <UqPortraitDetails
              classes={{ root: styles.speaker }}
              name={speaker.name}
              description={speaker.description.description}
              role={`${speaker.role}, ${speaker.company}`}
              src={speaker.image.url}
              textAlign="right"
            />
          </div>
          <div className="d-none d-lg-block">
            <UqPortraitDetails
              classes={{ root: styles.speaker }}
              name={speaker.name}
              description={speaker.description.description}
              role={`${speaker.role}, ${speaker.company}`}
              src={speaker.image.url}
              textAlign="left"
            />
          </div>
        </>
      );
    }

    return (
      <UqPortraitDetails
        classes={{ root: styles.speaker }}
        name={speaker.name}
        description={speaker.description.description}
        role={`${speaker.role}, ${speaker.company}`}
        src={speaker.image.url}
        textAlign="right"
      />
    );
  }
}

function getCTAText(enabled) {
  return enabled ? 'Save Your Spot' : 'Join Our Waitlist';
}
